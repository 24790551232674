<template>
  <div>
    <Loader />
    <div class="wrapper">
      <!-- Sidebar  -->
      <SideBarStyle1 :items="verticalMenu" :logo="logo" @toggle="sidebarMini" />

      <vue-pull-refresh :on-refresh="reloadPage">
        <!-- TOP Nav Bar -->
        <NavBarGide title="Dashboard" :homeURL="{ name: 'dashboard1.home' }" @toggle="sidebarMini" :logo="logo"></NavBarGide>
      </vue-pull-refresh>

      <!-- Right Nav Bar -->
      <RightSideBar toggleClass="top-50 setting-toggle iq-card">
        <i class="ri-sound-module-fill font-size-18 text-primary" slot="icon" />
      </RightSideBar><!-- Right Nav Bar -->

      <!-- Main Container -->
      <div id="content-page" class="content-page pr-0">
        <div class="container-fluid uptolaptop pr-1">
          <div :class="currentRouteName === 'gide.list' ? 'container-fluid mobileContainer pl-1' : 'container-fluid pl-1'">
            <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in" :leave-active-class="`animated ${animated.exit}`">
              <router-view/>
            </transition>
          </div>
        </div>
      </div><!-- Main Container -->

      <!-- Mesh Chat -->
      <iframe v-if="isMeshOpen && meshurl" class="chat_box_iframe" :style="setIframeStyles()" :src=meshurl frameborder="0" width="320px" height="600px" scrolling="yes"></iframe>
      <div style="position: fixed;right: 40px;bottom: 40px;" v-if="!isPageQuestionQuiz">
        <button
          type="button"
          class="btn-primary"
          style="border:0px; border-radius: 200px; box-shadow: #666 0px 7px 29px 0px;"
          @click="openMesh()"
        >
          <i class="ri-message-3-line pointer" style="padding: 8px 8px 8px 8px;font-size: 36px; position: relative;top: 1px;" title="Messages"></i>
        </button>
      </div><!-- Mesh Chat -->
    </div>

    <FooterStyle1>
      <template v-slot:left>
        <li class="list-inline-item">
          <a href="/content/PrivacyPolicy" target="_blank">Privacy Policy</a>
        </li>
        <li class="list-inline-item">
          <a href="/content/Tnc" target="_blank">Terms of Use</a>
        </li>
      </template>
      <template v-slot:right>
        Copyright {{new Date().getFullYear()}} <a href="#">Gide</a> All Rights Reserved.
      </template>
    </FooterStyle1>
  </div>
</template>
<script>
import Loader from "../components/socialvue/loader/Loader.vue"
import SideBarStyle1 from "../components/socialvue/sidebars/SideBarStyle1.vue"
import NavBarGide from "../components/socialvue/navbars/NavBarGide.vue"
import SideBarItems from "../FackApi/json/SideBarGide"
import profile from "../assets/images/user/user-1.jpeg"
import logo from "../assets_gide/img/logo/GideWhiteNRed.jpeg"
import RightSideBar from "../components/socialvue/rightsidebar/RightSideBarGide.vue"
import FooterStyle1 from "../components/socialvue/footer/FooterStyle1"
import { socialvue } from "../config/pluginInit"
import { mapActions } from "vuex"
import SideBarMenu from "../Utils/sidebarMenu.js"
var vuePullRefresh = require("vue-pull-refresh")

export default {
  name: "Layout1",
  components: {
    Loader,
    SideBarStyle1,
    NavBarGide,
    RightSideBar,
    FooterStyle1,
    vuePullRefresh
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    },
    userData () {
      return this.$store.getters["User/userData"]
    },
    meshurl () {
      return this.$store.getters["MeshUrl/meshUrl"]
    },
    openMeshLs () {
      return this.$store.getters["OpenMesh/openMesh"]
    }
  },
  watch: {
    openMeshLs (newVal) {
      // Watch on the store variable and open the mesh iframe. Example: If u follow a club, then automatically the iframe will open. or if you accespt the invite link sent on email or WA then iframe will open and show the groups
      this.isMeshOpen = newVal
    },
    "$route.path": function (newVal, oldVal) {
      if (newVal.includes("question_quiz")) {
        this.isPageQuestionQuiz = true
      }
      else {
        this.isPageQuestionQuiz = false
      }
    }
  },
  // sidebarTicket
  data () {
    return {
      animated: { enter: "zoomIn", exit: "zoomOut" },
      verticalMenu: SideBarItems,
      userProfile: profile,
      logo: logo,
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      isMeshOpen: false,
      VUE_APP_ENV: process.env.VUE_APP_ENV,
      isPageQuestionQuiz: false
    }
  },
  mounted () {
    if (this.isMeshOpen) {
      this.$store.dispatch("OpenMesh/setOpenMesh", false)
    }

    this.logo = logo
    SideBarMenu.getSideBarMenuObjList()
    this.changeText()
  },
  methods: {
    openMesh () {
      // set mesh_url into local storage
      const meshUrl = process.env.VUE_APP_MESH_SERVER_URL + `/gateway/${this.userData.user_id}`
      this.$store.dispatch("MeshUrl/setMeshUrl", meshUrl)

      if (this.isMobileDevice) {
        window.open(meshUrl)
      }
      else {
        // Call Iframe for Desktop
        this.isMeshOpen = !this.isMeshOpen // If hidden then show else Hide
        this.$store.dispatch("OpenMesh/setOpenMesh", this.isMeshOpen)
      }
    },
    setIframeStyles () {
      return `
        position: fixed;
        bottom:10%; 
        right:5%;
      `
    },
    changeText () {
      const textPullTORefresh = document.querySelector(".pull-down-content--label")
      if (textPullTORefresh) {
        textPullTORefresh.innerText = "Pull to refresh"
      }
    },
    reloadPage () {
      window.location.reload()
    },
    sidebarMini () {
      socialvue.triggerSet()
    },
    logout () {
      localStorage.removeItem("user")
      localStorage.removeItem("access_token")
      this.$router.push({ name: "auth1.sign-in1" })
    },
    langChange (lang) {
      this.langChangeState(lang)

      this.$i18n.locale = lang.value
      document.getElementsByClassName("iq-show")[0].classList.remove("iq-show")
    },
    ...mapActions({
      langChangeState: "Setting/setLangAction"
    })
  }
}
</script>
<style lang="scss" scoped>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");

  .pull_to_refresh{
    .content-page{
      padding-top: 15px;
    }
  }
</style>
