<template>
  <!-- Right Sidebar Panel Start-->
  <div :class="`right-sidebar-mini `+miniClass">
    <div class="right-sidebar-panel p-0">
      <div class="iq-card shadow-none">
        <b-card-title class="p-2 pl-3 border-bottom font-size-16">
          <span class="">
            Interests
          </span>
          <i class="fa fa-pencil mr-2 mt-2 pull-right font-size-14" style="cursor: pointer;" @click="openInterestModal()"></i>
        </b-card-title>

        <div class="iq-card-body p-0 pt-2">
          <!-- chat!-->
          <div class="media-height iq-chat-data-block active-block" v-show="showChat">
            <div class="media align-items-center">
              <Interest :propShowInterestModal="showInterestModal" @emitCloseInterestModal="emitCloseInterestModal" />
            </div>
            <UserInterest :propRefreshUserInterests="refreshUserInterests" @emitUserInterestsNotAdded="emitUserInterestsNotAdded" />
          </div>
          <!-- customization !-->
          <iq-card class="shadow-none iq-customizer-block active-block" v-show="!showChat">
            <template v-slot:body>
              <b-row>
                <div class=" mt-4 mb-4 w-100" />
                <b-col cols="12" class="justify-content-between">
                  <h4 class="text-left mb-2">
                    {{ $t('customizer.colorMode') }}
                  </h4>
                  <div class="text-center d-flex">
                    <img :src="require('../../../assets/images/customizer/light.jpg')" alt="light" @click="themeMode(false)" class="img-fluid" style="height: 120px;border: 2px solid var(--iq-primary)">
                    <img :src="require('../../../assets/images/customizer/dark.jpg')" alt="dark" @click="themeMode(true)" class="img-fluid ml-2" style="height: 120px;border: 2px solid var(--iq-primary)">
                  </div>
                </b-col>
                <div class="border mt-4 mb-4 w-100" />
                <b-col cols="12" class="justify-content-between">
                  <h4 class="text-left mb-2">
                    {{ $t('customizer.rtlMode')}}
                  </h4>
                  <div class="text-center d-flex">
                    <img :src="require('../../../assets/images/customizer/rtl.jpg')" alt="ltr" @click="rtlChange(true)" class="img-fluid" style="height: 120px;border: 2px solid var(--iq-primary)">
                    <img :src="require('../../../assets/images/customizer/rtl.jpg')" alt="rtl" @click="rtlChange(false)" class="img-fluid ml-2" style="height: 120px;border: 2px solid var(--iq-primary)">
                  </div>
                </b-col>
              </b-row>
            </template>
          </iq-card>
          <!-- toggle!-->
          <div class="right-sidebar-toggle bg-primary mt-3" @click="toggleMini">
            <template v-if="desktopView">
              <i class="ri-arrow-right-line side-left-icon"></i>
              <i class="ri-arrow-left-line side-right-icon"><span class="ml-3 d-inline-block">Close Menu</span></i>
            </template>
            <template v-else>
              <i class="ri-arrow-left-line side-right-icon"></i>
              <i class="ri-arrow-right-line side-left-icon"><span class="ml-3 d-inline-block">Close Menu</span></i>
            </template>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<style>
.right-sidebar-panel {
  height: 100%!important;
  }
  .right-sidebar-mini {
    height: 100%!important;
  }
  @media screen and (min-width:1299px) and (max-width:1496px){
      .hide-right-side-bar{
      -webkit-transform: translateX(calc(143% - 8em)) !important;
      transform: translateX(calc(143% - 8em)) !important;
      }
    }
</style>

<script>
import loader from "../../../assets/images/logo.png"
import darkLoader from "../../../assets/images/logo-white.png"
import { mapActions } from "vuex"
import Interest from "../../Interest.vue"
import UserInterest from "../../UserInterest.vue"

export default {
  name: "RightSideBarStyle1",
  components: { Interest, UserInterest },
  data () {
    return {
      miniClass: "hide-right-side-bar",
      showChat: true,
      animated: { enter: "zoomIn", exit: "zoomOut" },
      animateClass: [
        { value: { enter: "zoomIn", exit: "zoomOut" }, text: "Zoom" },
        { value: { enter: "fadeInUp", exit: "fadeOutDown" }, text: "Fade" },
        { value: { enter: "slideInLeft", exit: "slideOutRight" }, text: "Slide" },
        { value: { enter: "rotateInDownLeft", exit: "rotateOutDownLeft" }, text: "Roll" }
      ],
      logo: loader,
      rtl: false,
      users: [],
      showInterestModal: false,
      desktopView: false,
      rightSideBarMini: false
    }
  },
  props: {
    toggleClass: { type: String, default: "" }
  },
  computed: {
    hasIconSlot () {
      return !!this.$slots.icon
    },
    hasOpenIconSlot () {
      return !!this.$slots.openIcon
    },
    hasCloseIconSlot () {
      return !!this.$slots.closeIcon
    },
    refreshUserInterests () {
      return this.$store.state.User.refreshUserInterests
    }
  },
  mounted () {
    if (window.screen.width > 1199) {
      this.desktopView = true
    }
    this.toggleMini()
  },
  methods: {
    toggleMini () {
      this.rightSideBarMini = !this.rightSideBarMini
      this.checkRightSideBar()
    },
    checkRightSideBar () {
      if (this.rightSideBarMini) {
        this.miniClass = "hide-right-side-bar hide-rightside-bar"
      }
      else {
        this.miniClass = "right-sidebar"
      }
    },
    themeMode (mode) {
      this.dark = mode
      this.modeChange({ rtl: this.rtl, dark: mode })
      if (mode) {
        this.logo = darkLoader
      }
      else {
        this.logo = loader
      }
      this.$emit("onLogo", this.logo)
    },
    rtlChange (mode) {
      this.rtl = mode
      this.modeChange({ rtl: mode, dark: this.darkMode })
      if (this.darkMode) {
        this.logo = darkLoader
      }
      else {
        this.logo = loader
      }
    },
    reset () {
      this.animated = { enter: "zoomIn", exit: "zoomOut" }
      this.themeMode(false)
      this.rtlChange(false)
    },
    emitCloseInterestModal (addedNewInterests) {
      if (addedNewInterests) {
        this.$store.dispatch("User/refreshUserInterests")
      }
      this.showInterestModal = false
    },
    openInterestModal () {
      this.showInterestModal = Math.random()
    },
    ...mapActions({
      modeChange: "Setting/layoutModeAction"
    }),
    /**
     * emitUserInterestsNotAdded
     */
    emitUserInterestsNotAdded () {
      this.showInterestModal = true
    }
  }
}
</script>
